import React, { useState } from 'react';
// import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import '../Project-test/project-test.css';
import ScrollToTopButton from '../ScrollButton';
import Amaryllis1 from '../../images/projects-slider/amaryllis-1.webp';
import Amaryllis2 from '../../images/projects-slider/amaryllis-2.webp';
import Amaryllis3 from '../../images/projects-slider/amaryllis-3.webp';
import Flocert1 from '../../images/projects-slider/flocert-1.webp';
import Flocert2 from '../../images/projects-slider/flocert-1.webp';
import Flocert3 from '../../images/projects-slider/flocert-1.webp';
import Hometheater1 from '../../images/projects-slider/ht-mangalore-1.webp';
import Hometheater2 from '../../images/projects-slider/ht-mangalore-2.webp';
import Hometheater3 from '../../images/projects-slider/ht-mangalore-3.webp';
import Sabic1 from '../../images/projects-slider/sabic-bangalore.webp';
import Sabic2 from '../../images/projects-slider/sabic-bangalore.webp';
import Sabic3 from '../../images/projects-slider/sabic-bangalore.webp';
import DRC1 from '../../images/projects-slider/drc-mysore.webp';
import DRC2 from '../../images/projects-slider/drc-mysore.webp';
import DRC3 from '../../images/projects-slider/drc-mysore.webp';
import Hospira1 from '../../images/projects-slider/hospira-chennai.webp';
import Hospira2 from '../../images/projects-slider/hospira-chennai.webp';
import Hospira3 from '../../images/projects-slider/hospira-chennai.webp';
import CMC1 from '../../images/projects-slider/cmc-image-1.webp';
import CMC2 from '../../images/projects-slider/cmc-image-1.webp';
import CMC3 from '../../images/projects-slider/cmc-image-1.webp';
import KIS1 from '../../images/projects-slider/proj-kodai-1.webp';
import KIS2 from '../../images/projects-slider/proj-kodai-2.webp';
import KIS3 from '../../images/projects-slider/proj-kodai-3.webp';
import Generalmotor1 from '../../images/projects-slider/gm-bangalore.webp';
import Generalmotor2 from '../../images/projects-slider/gm-bangalore.webp';
import Generalmotor3 from '../../images/projects-slider/gm-bangalore.webp';
import DPS1 from '../../images/projects-slider/acousticproject-acoustical-surfaces.webp';
import DPS2 from '../../images/projects-slider/acousticproject-acoustical-surfaces.webp';
import DPS3 from '../../images/projects-slider/acousticproject-acoustical-surfaces.webp';
import Navelbase1 from '../../images/projects-slider/proj_13.webp';
import Navelbase2 from '../../images/projects-slider/proj_13.webp';
import Navelbase3 from '../../images/projects-slider/proj_13.webp';
import Zoho1 from '../../images/projects-slider/zoho-chennai.webp';
import Zoho2 from '../../images/projects-slider/zoho-chennai.webp';
import Zoho3 from '../../images/projects-slider/zoho-chennai.webp';
import Twitter1 from '../../images/projects-slider/twitter-india.webp';
import Twitter2 from '../../images/projects-slider/twitter-india.webp';
import Twitter3 from '../../images/projects-slider/twitter-india.webp';
import Wipro1 from '../../images/projects-slider/wipro-bangalore.webp';
import Wipro2 from '../../images/projects-slider/wipro-bangalore.webp';
import Wipro3 from '../../images/projects-slider/wipro-bangalore.webp';
import Goldman1 from '../../images/projects-slider/goldman-bangalore.webp';
import Goldman2 from '../../images/projects-slider/goldman-bangalore.webp';
import Goldman3 from '../../images/projects-slider/goldman-bangalore.webp';
import EIBFS1 from '../../images/projects-slider/p1eibfs.webp';
import EIBFS2 from '../../images/projects-slider/p1eibfs.webp';
import EIBFS3 from '../../images/projects-slider/p1eibfs.webp';
import MoF1 from '../../images/projects-slider/p1museum.webp';
import MoF2 from '../../images/projects-slider/p1museum.webp';
import MoF3 from '../../images/projects-slider/p1museum.webp';
import Louvre1 from '../../images/projects-slider/p1louvre.webp';
import Louvre2 from '../../images/projects-slider/p1louvre.webp';
import Louvre3 from '../../images/projects-slider/p1louvre.webp';
import Palace1 from '../../images/projects-slider/p1palace.webp';
import Palace2 from '../../images/projects-slider/p1palace.webp';
import Palace3 from '../../images/projects-slider/p1palace.webp';
import KAPSARC1 from '../../images/projects-slider/p1kapsarc.webp';
import KAPSARC2 from '../../images/projects-slider/p1kapsarc.webp';
import KAPSARC3 from '../../images/projects-slider/p1kapsarc.webp';
import Ferrari1 from '../../images/projects-slider/p1ferrari.webp';
import Ferrari2 from '../../images/projects-slider/p1ferrari.webp';
import Ferrari3 from '../../images/projects-slider/p1ferrari.webp';
import Bosch1 from '../../images/projects-slider/bosch-bangalore.webp';
import Bosch2 from '../../images/projects-slider/bosch.webp';
import Bosch3 from '../../images/projects-slider/bosch-meeting-hall.webp';
import Teleperformance1 from '../../images/projects-slider/project-teleperformance1.webp';
import Teleperformance2 from '../../images/projects-slider/project-teleperformance2.webp';
import Teleperformance3 from '../../images/projects-slider/project-teleperformance3.webp';
import Bhutan1 from '../../images/bhutanmain.jpeg';
import Bhutan2 from '../../images/bhutan.jpeg';
import Bhutan3 from '../../images/bhutan3.jpg';
import Biocon1 from '../../images/biocon.jpeg';
import Biocon2 from '../../images/biocon2.jpeg';
import Biocon3 from '../../images/biocon3.jpeg';
import Epam1 from '../../images/Epam.jpeg';
import Epam2 from '../../images/epam2.jpeg';
import Epam3 from '../../images/epam3.jpeg';
import hdfc1 from '../../images/hdfc.jpeg';
import hdfc2 from '../../images/hdfc2.jpeg';
import hdfc3 from '../../images/hdfc3.jpeg';
import rio1 from '../../images/rio1.jpeg';
import rio2 from '../../images/rio2.jpeg';
import rio3 from '../../images/rio3.jpeg';
import myntra1 from '../../images/myntra.jpeg';
import myntra2 from '../../images/myntra2.jpeg';
import myntra3 from '../../images/myntra3.jpeg';
import ipop1 from '../../images/ipopaudio.jpeg';
import ipop2 from '../../images/ipop2.jpg';
import ipop3 from '../../images/ipop3.jpg';
import library1 from '../../images/kalaignar.jpeg';
import library2 from '../../images/kalaignar2.jpg';
import library3 from '../../images/kalaignar3.jpg';
import dlf1 from '../../images/dlf.jpeg';
import dlf2 from '../../images/dlf2.jpeg';
import dlf3 from '../../images/dlf3.jpeg';

const projectsData = [
    {
        id: 32,
        name: "HDFC Auditorium",
        location: "Mumbai, Maharashtra",
        client: "",
        architect: "",
        aboutText: `HDFC Bank Residential Training Centre is located as a part of a commercial sector at Palava- a new township on mumbai's outskirts.Training cum Residential Block having 8 floor levels whereas Bank branch is having ground and 1 floor level. The project aims at various firsts by aiming for a platinum green building rating as well as Health + wellbeing rating.`,
        scope: "Supply and Installation of FABRIWALL System.",
        images: [hdfc1, hdfc2, hdfc3],
        wordLimit: 170
    },
    {
        id: 31,
        name: "Google Rio",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `The 12-storey scheme has some of the largest single floor plates in India accommodating more than 7,000 employees with a variety of self-sustainable but connected communities on every floor plate, each designed to enhance interaction between employees and different teams.`,
        scope: "Supply of FOAMSTOPPOLYURETHANE.",
        images: [rio1, rio2, rio3],
        wordLimit: 160
    },
    {
        id: 30,
        name: "Myntra",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `Myntra is one of the largest fashion e-commerce stores in India that deals withawiderange of fashion and lifestyle products for men, women, and kids. It sells high-qualityclothes, branded footwear, bags and backpacks, beauty and personal care products, home and living accessories, and more.`,
        scope: "Supply and installation of Fabrisorb Wall panels and Clouds.",
        images: [myntra1, myntra2, myntra3],
        wordLimit: 170
    },
    {
        id: 29,
        name: "Ipop Audio-Experience Centre",
        location: "Pondicherry",
        client: "",
        architect: "",
        aboutText: `IPOP Audio is a premier destination for home theatre enthusiasts, offering a state-of-the-art experience centre where customers can explore the latest in audio-visual technology. With a commitment to delivering unparalleled sound quality and immersive experiences, IPOP Audio has established itself as a leader in the home theatre industry.`,
        scope: "Assessment, Planning, Customisation and Supply of D'ecopanel to achieve a great appeal to acoustics as well as aesthetics.",
        images: [ipop1, ipop2, ipop3],
        wordLimit: 170
    },
    {
        id: 28,
        name: "Kalaignar Memorial Library",
        location: "Madurai, Tamil Nadu",
        client: "",
        architect: "",
        aboutText: `The Kalaignar Centenary Library, equipped with modern facilities including a digital section, and containing over three lakh titles and an exclusive children's section, was inaugurated by Tamil Nadu Chief Minister M K Stalin.The six-storeyed structure, which resembles the Anna Centenary library in Chennai, has been constructed on 2.13 lakh square foot area at a cost of Rs 120. 75 crore by the Public Works Department.`,
        scope: "Supply & Installation of FABRIWALL, D’ECOPANEL-Baffles & ENVIROCOUSTIC WOOD WOOL.",
        images: [library1, library2, library3],
        wordLimit: 170
    },
    {
        id: 27,
        name: "DLF The Camellias",
        location: "Gurugram",
        client: "",
        architect: "",
        aboutText: `DLF Camellias in Golf Course Road, Gurgaon is one of the finest luxury condominium project built by the prestigious firm of DLF. This high-end residential project offers premium residences in the configuration of 4BHK, 5BHK and 6BHK units.`,
        scope: "Supply & Installation of Fabriwall Acoustic Plaster in ceilings.",
        images: [dlf1, dlf2, dlf3],
        wordLimit: 170
    },
    {
        id: 26,
        name: "Epam",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `EPAM India has fast grown to be a 7,100+ people organization across five locations, its a part of a global network of advisors, consultants, engineers, scientists and creatives who enable customers to be competitive and disruptive in the marketplace through innovative technology solutions while helping them to navigate successfully through multiple waves of technology change.`,
        scope: "Supply and Installation of Acoustic Plaster.",
        images: [Epam1, Epam2, Epam3],
        wordLimit: 170
    },
    {
        id: 25,
        name: "Biocon",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `Biocon Limited is an Indian biopharmaceutical company based in Bangalore. It was founded by Kiran Mazumdar-Shaw in 1978. The company manufactures generic active pharmaceutical ingredients that are sold in approximately 120 countries, including the United States and Europe. Biocon is the first company worldwide to develop recombinant human insulin (rh-insulin) on a Pichia expression system.`,
        scope: "Supply and Installation of FABRIWALL system.",
        images: [Biocon1, Biocon2, Biocon3],
        wordLimit: 165
    },
    {
        id: 24,
        name: "GCIT Bhutan",
        location: "Bhutan",
        client: "",
        architect: "",
        aboutText: `The Gyalpozhing College of Infrmation Technology (GCIT), officially inaugurated on6thOctober, 2017 is one of the integral colleges of the Royal University of Bhutan. It aims at giving hands- on experience which helps the students understand the practical implementation aspects and the concepts associated with it.Furthermore, the collegeaimstodiversify the programmes by including more practical and job-oriented course in thelong-run.`,
        scope: "Supply & Installation of POLYSORB WALL PANELS & CLOUDS",
        images: [Bhutan1, Bhutan2, Bhutan3],
        wordLimit: 170
    },
    {
        id: 23,
        name: "Teleperformance",
        location: "Indore , Madhya Pradesh",
        client: "",
        architect: "",
        aboutText: `Teleperformance SE, is an omnichannel company headquarters in France. The company provides customer acquisition management, customer care, technical support, debt collection, social media services, and other services. It operates in 91 countries and serves 170 markets.`,
        scope: "Supply & Installation of D'ecopanel.",
        images: [Teleperformance1, Teleperformance2, Teleperformance3],
        wordLimit: 155
    },
    {
        id: 22,
        name: "Robert Bosch Engineering & Business Solutions Pvt.Ltd.",
        location: "Bangalore, India",
        client: "",
        architect: "",
        aboutText: `In India, Bosch has set up its first sales agency in 1922 at Kolkata and started its manufacturing operations in 1951. This has grown over the years to 18 manufacturing sites and seven development and application centres. With a legacy of 100 years, Bosch India is a leading technology and services provider in the areas of Mobility Solutions, Industrial Technology, Consumer Goods, and Energy and Building Technology. Bosch India has the largest development centre outside Germany for end to end engineering and technology solutions.`,
        scope: "Supply & Installation of Fabriwall Stretch Fabric Acoustic Wall Panel System.",
        images: [Bosch1, Bosch2, Bosch3],
        wordLimit: 170
    },
    {
        id: 21,
        name: "Ferrari World",
        location: "Abu Dhabi, UAE",
        client: "",
        architect: "",
        aboutText: `The 86,000 m2 enclosed area of Ferrari World Abu Dhabi can fit 7 football fields in 'head to toe’. The total roof area of 200,000 m2 uses enough aluminum to cover 16,750 Ferraris, or if you laid the roof flat you could place 20,100 Ferrari's side by side and end to end. If Ferrari World Abu Dhabi was turned upright, it would be the tallest man made structure in the world at over 300 floors!`,
        scope: "We installed fabric wrapped acoustic wall panel system on the wall to increase the sound absorption there by to reduce reverberation.",
        images: [Ferrari1, Ferrari2, Ferrari3],
        wordLimit: 165
    },
    {
        id: 20,
        name: "KAPSARC",
        location: "Riyadh, Saudi Arabia",
        client: "",
        architect: "",
        aboutText: `An independent non-profit organization dedicated to researching economics and technology across all platforms of energy. Designed by Zaha Hadid Architects, the modular structure is a living, organic scheme where expansions to the building are allowed to grow and multiply like cells – without compromising the visual integrity of the project. Construction Area: 66,000m2; Site Area: 530,000 m2; Footprint Area: 28,500 m2`,
        scope: "Supply & installation of acoustic wall panels, Echo Panels & Acoustic Treatment for the walls & movable partitions.",
        images: [KAPSARC1, KAPSARC2, KAPSARC3],
        wordLimit: 160
    },
    {
        id: 19,
        name: "Presidential Palace",
        location: "Abu Dhabi, UAE",
        client: "",
        architect: "",
        aboutText: `The location of the new Presidential Palace is in Ras Al Akhdar peninsula adjacent to the Emirates Palace Hotel, Abu Dhabi. The development will encompass approximately 150 hectares, and will include the Palace and the required ancillary buildings. The Palace will exceed 160000m2 of built area and will contain several VIP wings, a Ministers wing, and several Majlis and Dining Halls.`,
        scope: "Supply & Installation of acoustical Wall panels. It was a great opportunity to be part of this iconic project.",
        images: [Palace1, Palace2, Palace3],
        wordLimit: 165
    },
    {
        id: 18,
        name: "Louvre",
        location: "Abu Dhabi, UAE",
        client: "",
        architect: "",
        aboutText: `Abu Dhabi’s Tourism Development & Investment Company (TDIC) has completed the installation of the dome structure in its upcoming attraction Louvre Abu Dhabi, Designed by architect Jean Nouvel. Louvre Abu Dhabi encompass 9,200 square metres of art galleries including a 6,681 sqm Permanent Gallery and a 2,364 sqm Temporary Gallery.`,
        scope: "We have been instrumental in treating the Echo and optimizing the Reverberation Times by introducing more than 22,000SQM of acoustic wood wool.",
        images: [Louvre1, Louvre2, Louvre3],
        wordLimit: 160
    },
    {
        id: 17,
        name: "The Museum of Future",
        location: "Dubai, UAE",
        client: "",
        architect: "",
        aboutText: `The Museum of the Future is a visionary cultural institution currently under construction in Dubai, a showplace for a new era - a center of creativity and hope where you can see, touch and shape our shared future.`,
        scope: "Acoustical Plaster ceiling in Auditorium below the walkway & Hall area.",
        images: [MoF1, MoF2, MoF3],
        wordLimit: 170
    },
    {
        id: 16,
        name: "Emirates Institute of Banking & Financial Studies",
        location: "Dubai, UAE",
        client: "",
        architect: "",
        aboutText: `Emirates Institute For Banking and Financial Studies (EIBFS) has made quantum leaps by delivering world className education, training and allied services in the area of Banking and Finance at its three campuses strategically located at Sharjah, Abu Dhabi and Dubai.`,
        scope: "Acoustical Interior fit out which include In-house acoustic consultancy & Design, Acoustical wall treatment & ceiling.",
        images: [EIBFS1, EIBFS2, EIBFS3],
        wordLimit: 170
    },
    {
        id: 15,
        name: "Goldman Sachs",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `The Goldman Sachs Group, Inc. is a leading global investment banking, securities and investment management firm that provides a wide range of financial services to a substantial and diversified client base that includes corporations, financial institutions, governments and individuals. Founded in 1869, the firm is headquartered in New York and maintains offices in all major financial centers around the world.`,
        scope: "Acoustic Wall Treatment.",
        images: [Goldman1, Goldman2, Goldman3],
        wordLimit: 170
    },
    {
        id: 14,
        name: "Wipro Technologies",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Wipro Technologies Ltd. a division of Wipro Limited (NYSE:WIT), is amongst the largest global IT services, BPO and Product Engineering companies. In addition to the IT business, Wipro also has leadership position in niche market segments of consumer products and lighting solutions.`,
        scope: "Acoustic Wall Treatment.",
        images: [Wipro1, Wipro2, Wipro3],
        wordLimit: 160
    },
    {
        id: 13,
        name: "Twitter India",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Twitter is an online news and social networking service on which users post and interact with messages known as tweets. Twitter, Inc. is based in San Francisco, California, and has more than 25 offices around the world.`,
        scope: "Acoustical Wall Treatment",
        images: [Twitter1, Twitter2, Twitter3],
        wordLimit: 170
    },
    {
        id: 12,
        name: "Zoho Corporation",
        location: "Chennai",
        client: "",
        architect: "",
        aboutText: `Zoho Corporation is a multi-national business software development company. Zoho Corp's offerings focus on web-based business tools and information technology solutions, including an office tools suite, internet of things management platform, and a suite of IT management software.`,
        scope: "Supply & Installation of Acoustic Panel System and Acrylic Sheet",
        images: [Zoho1, Zoho2, Zoho3],
        wordLimit: 155
    },
    {
        id: 11,
        name: "Navel Base Academy",
        location: "Vishakapatnam",
        client: "",
        architect: "",
        aboutText: `Naval Maritime Academy, Visakhapatnam provides quality education to meet the needs of a growing Global Shipping Industry. The Academy has been recognised by the Director General of Shipping, India and would award B.Sc (Tech) Marine Degree from College of Engineering, Andhra University.`,
        scope: "Supply & Installation of Acoustic Panel",
        images: [Navelbase1, Navelbase2, Navelbase3],
        wordLimit: 155
    },
    {
        id: 10,
        name: "Delhi Public School",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `Delhi Public School Bangalore East is a member of the prestigious DPS family comprising of over a hundred schools located in India as well as overseas.The DPS network in India spans across the geographical extent of our country and beyond. The schools abroad include the ones at Nepal, Indonesia, Singapore, United Arab Emirates, Qatar and Kuwait.`,
        scope: "We were entrusted with installation of Polysorb Baffles along with Polysorb Wall Panels in the swimming pool area. Our design and Acoustic treatment optimized the reverberation.",
        images: [DPS1, DPS2, DPS3],
        wordLimit: 160
    },
    {
        id: 9,
        name: "General Motors",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `General Motors India Private Limited is a partnership between General Motors and SAIC that is engaged in the automobile business in India`,
        scope: "Acoustic Anaechoic Chamber",
        images: [Generalmotor1, Generalmotor2, Generalmotor3],
        wordLimit: 170
    },
    {
        id: 8,
        name: "Kodaikanal International School",
        location: "Kodaikanal",
        client: "",
        architect: "",
        aboutText: `Kodaikanal International School is a co-educational independent residential school offering education for grades P-12. It is located on 43 acres in Kodaikanal, Dindigul, Tamil Nadu, India. With over a hundred years of international education in its history, KIS has high standards to match its high altitude.`,
        scope: "Supply & Installation of acoustical wall panels for the music jamming studio. Acoustically treating the ceiling and walls of the music room to reduce the reverberation time to provide the best performance results when loud music is being played.",
        images: [KIS1, KIS2, KIS3],
        wordLimit: 165
    },
    {
        id: 7,
        name: "Christian Medical College",
        location: "Vellore, Tamil Nadu",
        client: "",
        architect: "",
        aboutText: `The Christian Medical College and Hospital (CMC) is a private, minority-run educational and research institute that includes a network of primary, secondary and tertiary care hospitals in and around Vellore, Tamil Nadu, India. Founded in 1900 by an American missionary, Dr Ida S. Scudder, CMC has brought many significant achievements to India.`,
        scope: "To treat the Wall Partition Acoustic Isolation to achieve 39dB addition to that absorption wall paneling of NRC 0.9 and suspended ceilings with Acoustic isolation and renovating the existing rooms by retrofitting with current technology. Construction area : 2361 sq.ft",
        images: [CMC1, CMC2, CMC3],
        wordLimit: 170
    },
    {
        id: 6,
        name: "Hospira Healthcare",
        location: "Chennai",
        client: "",
        architect: "",
        aboutText: `Hospira Healthcare India Private Limited manufactures injectable formulations and active pharmaceutical ingredients (APIs). The company was formerly known as Ojas Pharmaceuticals India Private Limited Hospira Healthcare India Private Limited operates as a subsidiary of Pfizer Inc.`,
        scope: "Instalation of Acoustic Wall Panels.",
        images: [Hospira1, Hospira2, Hospira3],
        wordLimit: 160
    },
    {
        id: 5,
        name: "DRC Cinemas",
        location: "Mysore",
        client: "",
        architect: "",
        aboutText: `Mysore's first Multiplex is here fulfilling the long cherished dream of many a Mysorean! Top of the line equipment for acoustics and projection giving beautiful ambience topped off with stylish lobby and concession counters screens films of all popular languages.`,
        scope: "Acoustic Treatment",
        images: [DRC1, DRC2, DRC3],
        wordLimit: 165
    },
    {
        id: 4,
        name: "Sabic Research & Technology Pvt. Ltd",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Sabic Research And Technology Private Limited is a Private incorporated on 19 February 1998. It is classNameified as Non-govt company and is registered at Registrar of Companies, Bangalore.`,
        scope: "Supply & Installation of Acoustical Door and Partition",
        images: [Sabic1, Sabic2, Sabic3],
        wordLimit: 170
    },
    {
        id: 3,
        name: "Home Theater, Mangalore",
        location: "Mangalore",
        client: "",
        architect: "",
        aboutText: ``,
        scope: "Home Theater Installation, Supply & Installation of Acoustic Walls and Flooring",
        images: [Hometheater1, Hometheater2, Hometheater3],
        wordLimit: 170
    },
    {
        id: 2,
        name: "Flocert India",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Flocert is a leading certifier offering verification services to Fair-trade organizations globally. Flocert has supported the phenomenal growth of Fair-trade, a movement that has positively impacted millions of people across the world for over a decade.`,
        scope: "Supply & Installation of acoustical Fabric panels with echo eliminator as backing material for the conference room to reduce the reverberation time to provide better speech intelligibility.",
        images: [Flocert1, Flocert2, Flocert3],
        wordLimit: 170
    },
    {
        id: 1,
        name: "Amaryllis Healthcare Pvt Ltd, Bangalore",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Amaryllis Healthcare Private Limited, an ISO Certified Company, has emerged as a key partner of the healthcare sector in India over the last few years. Their product range extends from Disposable Surgical Drapes & Gowns to various Hygiene & Protective products.`,
        scope: "Supply and installation of Noise S.T.O.P Vinyl Barrier and stretch fabric system with Glass wool to increase sound isolation property of wall and to reduce reverberation time in the conference room which provides better speech intelligibility.",
        images: [Amaryllis1, Amaryllis2, Amaryllis3],
        wordLimit: 160
    },
];

const ProjectCard = ({ project }) => {
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => setIsReadMore(!isReadMore);

    return (
        <div className='container mt-5 mb-5'>
            <div className='row'>
                <div className="col-lg-7 mb-5">
                    <div className='top-img'>
                        <img className='top' src={project.images[0]} alt={project.name} />
                    </div>
                    <div className='bottom-img d-flex'>
                        <div>
                            <img className='img-fluid bot-img1' src={project.images[1]} alt={`${project.name}-2`} />
                        </div>
                        <div>
                            <img className='img-fluid bot-img2' src={project.images[2]} alt={`${project.name}-3`} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 project-content">
                    <div className='rno d-flex'><span>{project.id.toString().padStart(2, '0')}. </span><h5>{project.name}</h5></div><br />
                    <div className='location d-flex'><p>Location :</p><span className='pl-1'>{project.location}</span></div>
                    <p>Client: {project.client}</p>
                    <p>Architect: {project.architect}</p>
                    <p className='abt-project mb-0'>About Project:</p>
                    <p style={{ textAlign: "justify" }}>
                        {isReadMore ? project.aboutText.slice(0, project.wordLimit) : project.aboutText}
                        {project.aboutText.length > project.wordLimit && (
                            <b>
                                <span onClick={toggleReadMore} className='read-more-btn'>
                                    {isReadMore ? '...Read More' : 'Show Less'}
                                </span>
                            </b>
                        )}
                    </p>
                    <p className='scope-project mb-0'>Our Scope:</p>
                    <p>{project.scope}</p>
                </div>
            </div>
            <hr className='project-hr' />
        </div>
    );
}

function Project() {
    const projectsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(projectsData.length / projectsPerPage);

    const currentProjects = projectsData.slice((currentPage - 1) * projectsPerPage, currentPage * projectsPerPage);

    // Handle pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <Header />
            <section className="content projects">
                <div className='container-fluid p-0 pro-banner'>
                    <h3>Recently</h3>
                    <h1>Completed Projects</h1>
                </div>

                {currentProjects.map(project => (
                    <ProjectCard key={project.id} project={project} />
                ))}
                <ScrollToTopButton />

                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end pr-3 pb-3">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} aria-label="Previous">
                                &laquo;
                            </button>
                        </li>
                        {[...Array(totalPages).keys()].map(pageNumber => (
                            <li key={pageNumber + 1} className={`page-item ${currentPage === pageNumber + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)}>
                                    {pageNumber + 1}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} aria-label="Next">
                                &raquo;
                            </button>
                        </li>
                    </ul>
                </nav>

            </section>
            <Footer />
        </>
    );
}
export default Project;