import React from 'react';
import Header from '../header';
import '../products/products.css';
import { Container, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScrollToTopButton from '../ScrollButton';
import Footer from '../footer';
import { Helmet } from 'react-helmet';
import fabrisorb from '../../images/newproductslider/fabrisorb-pro.webp';
import farbrisorbdeco from '../../images/newproductslider/fabrisorb-deco-pro.jpeg';
import echoeliminator from '../../images/newproductslider/echoelim-pro.webp';
import mlvb from '../../images/newproductslider/mlvb-pro.webp';
import woodwool from '../../images/newproductslider/woodwool-pro.webp';
import decopanel from '../../images/newproductslider/decopanel-pro.webp';
import fabriwall from '../../images/newproductslider/fabriwall-pro.webp';
import woodtec from '../../images/newproductslider/woodtec-pro.webp';
import polyurethane from '../../images/newproductslider/foamstop-polyurethane-pro.webp';
import diffuso from '../../images/newproductslider/diffuso-pro.jpeg';
import polymax from '../../images/newproductslider/polymax-pro.webp';
import polysorb from '../../images/newproductslider/polysorb-pro.webp';
import polysorbdeco from '../../images/newproductslider/polysorb-deco-pro.webp';
import echotherm from '../../images/newproductslider/echotherm.png';
import decowood from '../../images/newproductslider/decowood-pro.jpeg';
import acoustydoor from '../../images/newproductslider/soundproof-door-pro.webp';
import knauf from '../../images/newproductslider/knauf-danoline-kraft.png';
import polyester from '../../images/newproductslider/polyester-pro.png';


function Products() {

    return (
        <>
            <Helmet>
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <title>Acoustic Products Manufacturer and Acoustic Products Supplier</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Our Acoustic Products" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.svg" />
                <meta property="og:locale" content="en-in" />
                <link href="https://www.acousticalsurfaces.in/products" rel="alternate" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/products" />
            </Helmet>
            <section id='products'>
                <div className='container-fluid'>
                    <Header />
                    {/* <div className='container'>
                        <div className='col-md-12 title'>Key Products</div>
                        <div className='row para'>
                            <div className='col-md-6 text-left'>
                                <Link to={'/fabrisorb'} style={{ textDecorationLine: "none" }}><p>FabriSorb</p></Link>
                                <Link to={'/fabrisorb-deco'} style={{ textDecorationLine: "none" }}><p>FabriSorb Deco</p></Link>
                                <Link to={'/echo-eliminator'} style={{ textDecorationLine: "none" }}><p>Echo Eliminator<sup>TM</sup></p></Link>
                                <Link to={'/mass-loaded-vinyl'} style={{ textDecorationLine: "none" }}><p>MLVB</p></Link>
                                <Link to={'/woodwool'} style={{ textDecorationLine: "none" }}><p>Wood Wool</p></Link>
                                <Link to={'/decopanel'} style={{ textDecorationLine: "none" }}><p>D'ecopanel</p></Link>
                                <Link to={'/fabriwall'} style={{ textDecorationLine: "none" }}><p>FabriWALL</p></Link>
                                <Link to={'/woodtec'} style={{ textDecorationLine: "none" }}><p>WoodTEC</p></Link>
                                <Link to={'/foamstop-pu'} style={{ textDecorationLine: "none" }}><p>FOAM STOP<sup>TM</sup>Polyurethane</p></Link>
                                <Link to={'/diffuso'} style={{ textDecorationLine: "none" }}><p>Diffuso</p></Link>
                                <Link to={'/polymax-polyester'} style={{ textDecorationLine: "none" }}><p>Polymax<sup>TM</sup></p></Link>
                            </div>
                            <div className='col-md-6 text-left'>
                                <Link to={'/polysorb'} style={{ textDecorationLine: "none" }}><p>Polysorb</p></Link>
                                <Link to={'/polysorb-deco'} style={{ textDecorationLine: "none" }}><p>Polysorb Deco</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Ecotherm</p></Link>
                                <Link to={'/decowood'} style={{ textDecorationLine: "none" }}><p>Decowood</p></Link>
                                <Link to={'/soundproof-interior-doors'} style={{ textDecorationLine: "none" }}><p>Acousty Door</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Knauf Danoline Kraft</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Sound Isolation Clip</p></Link>
                                <Link to={'/polyester-ceiling-tiles'} style={{ textDecorationLine: "none" }}><p>Polyester Ceiling Tiles</p></Link>
                            </div>
                        </div>
                    </div> */}

                    <Container className='mb-5'>
                        <hr />
                        <Row>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={fabrisorb} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/fabrisorb'}>
                                            <Card.Title>FabriSorb</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={farbrisorbdeco} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/fabrisorb-deco'}>
                                            <Card.Title>FabriSorb Deco</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={echoeliminator} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/echo-eliminator'}>
                                            <Card.Title>Echo Eliminator<sup>TM</sup></Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={mlvb} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/mass-loaded-vinyl'}>
                                            <Card.Title>MLVB</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={woodwool} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/woodwool'}>
                                            <Card.Title>Wood Wool</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={decopanel} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/decopanel'}>
                                            <Card.Title>D'ecopanel</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={fabriwall} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/fabriwall'}>
                                            <Card.Title>FabriWALL</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={woodtec} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/woodtec'}>
                                            <Card.Title>WoodTEC</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={polyurethane} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/foamstop-pu'}>
                                            <Card.Title>FOAM STOP<sup>TM</sup>Polyurethane</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={diffuso} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/diffuso'}>
                                            <Card.Title>Diffuso</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={polymax} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/polymax-polyester'}>
                                            <Card.Title>Polymax<sup>TM</sup></Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={polysorb} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/polysorb'}>
                                            <Card.Title>Polysorb</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={polysorbdeco} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/polysorb-deco'}>
                                            <Card.Title>Polysorb Deco</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={echotherm} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'#/'}>
                                            <Card.Title>Ecotherm</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={decowood} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/decowood'}>
                                            <Card.Title>Decowood</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={acoustydoor} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/soundproof-interior-doors'}>
                                            <Card.Title>Acousty Door</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={knauf} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'#/'}>
                                            <Card.Title>Knauf Danoline Kraft</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} sm={4}>
                                <Card>
                                    <Card.Img className="card-img" variant="top" src={polyester} />
                                    <Card.Body>
                                        <Link className='pro-link' to={'/polyester-ceiling-tiles'}>
                                            <Card.Title>Polyester Ceiling Tiles</Card.Title>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <hr />
                    </Container>

                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    )
}

export default Products